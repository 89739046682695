<template>
  <MainApp>
    <a-layout style="height: 100%">
      <a-layout-content :style="{ padding: '24px',  minHeight: 'auto' }">
        <slot/>
        <CommonFooterLinks/>
      </a-layout-content>
    </a-layout>
  </MainApp>
</template>
<script lang="ts" setup>

useHead(defaultHead());
</script>
